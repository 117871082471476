import React from "react"
import { Link } from "gatsby"
function BlogHeader() {
  return (
    <div>
      <div style={{ minHeight: "115px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: "100%",
                maxWidth: "1192px",
                minWidth: "0",
                margin: "0 64px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  minHeight: "115px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "8px",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "0px",
                        marginTop: "-32px",
                        alignItems: "flex-end",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bolder",
                          fontSize: "1.5rem",
                          flexGrow: 1,
                        }}
                      >
                        <Link to="/blog"> anuwataravis.dev</Link>
                      </div>
                      <div style={{ display: "flex", flexDirection: "end" }}>
                        <a
                          href="https://webring.wonderful.software#anuwataravis.dev"
                          title="วงแหวนเว็บ"
                        >
                          <img
                            alt="วงแหวนเว็บ"
                            width="32"
                            height="32"
                            src="https://webring.wonderful.software/webring.black.svg"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogHeader
