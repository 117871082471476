import React from "react"
import Badge from "./Badge"
function BadgeList({ categories }) {
  let badgeList = []
  if (categories) {
    badgeList = categories.split("&")
  }

  return (
    <div style={{ display: "flex" }}>
      {badgeList.length > 0 &&
        badgeList.map(list => <Badge badgeName={list} key={list} />)}
    </div>
  )
}

export default BadgeList
