import React from "react"
import BlogLayout from "../components/Layout/Blog/BlogLayout"
import { graphql } from "gatsby"

function blogDetails({ data }) {
  const { frontmatter } = data.markdownRemark
  const { html } = data.markdownRemark
  return <BlogLayout frontmatter={frontmatter} html={html} />
}

export default blogDetails

export const query = graphql`
  query BlogDetail($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        stack
        title
        categories
        date
      }
    }
  }
`
