import React from "react"
import * as styles from "./badge.module.css"
function Badge({ badgeName }) {
  return (
    <div
      style={{
        borderLeft: "2px solid #333",
        padding: "2px 6px",
      }}
    >
      {badgeName}
    </div>
  )
}

export default Badge
