import React from "react"
import BlogHeader from "../../Header/Blog/BlogHeader"
import * as styles from "./blog-layout.module.css"
import BadgeList from "../../Blog/Badge/BadgeList"
import { Link } from "gatsby"
function BlogLayout({ frontmatter, html }) {
  const { date } = frontmatter
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <BlogHeader />
      <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={styles.blogDisplay}>
            <div>
              <h1 style={{ marginTop: "0.55em", fontSize: "28px" }}>
                {frontmatter.title}
              </h1>
            </div>
            <div>
              {new Date(date).toLocaleString(`en-US`, {
                day: "2-digit",
                year: "numeric",
                month: "long",
              })}
            </div>
            <div>
              <BadgeList categories={frontmatter.categories} />
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: html }}
              className={styles.blogContent}
            />
            <div
              style={{
                borderLeft: "2px solid black",
                padding: "1rem",
                marginBottom: "2rem",
              }}
            >
              <div>Thanks For Reading. 💻</div>
              <div>- Anu Wat - </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Link
                  to="/blog"
                  style={{
                    textAlign: "right",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <span>⬅</span> Back
                </Link>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "1.5rem",
                marginBottom: "10vh",
              }}
            >
              <div style={{ width: "150px" }}>
                <Link to="/">
                  <svg
                    width="100%"
                    viewBox="0 0 111 95"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="8"
                      y="7"
                      width="103"
                      height="88"
                      rx="12"
                      fill="#D8D8D8"
                    />
                    <rect
                      x="5"
                      y="5"
                      width="103"
                      height="87"
                      rx="12"
                      fill="#C4C4C4"
                    />
                    <rect width="104" height="88" rx="12" fill="#DBDBDB" />
                    <rect
                      x="7.79297"
                      y="7"
                      width="17.7517"
                      height="72.8562"
                      rx="2"
                      fill="#3B3B3C"
                    />
                    <rect
                      x="89.4504"
                      y="7"
                      width="15.1784"
                      height="71.0067"
                      transform="rotate(90 89.4504 7)"
                      fill="#3B3B3C"
                    />
                    <rect
                      x="78.7998"
                      y="7"
                      width="17.7517"
                      height="72.8562"
                      fill="#3B3B3C"
                    />
                    <path d="M101 25L101 40L2 40L2 25L101 25Z" fill="#DBDBDB" />
                  </svg>
                </Link>
              </div>
              <div>
                <div>
                  Anu Wat{" "}
                  <Link to="/">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 16H2V2H9V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C17.1 18 18 17.1 18 16V9H16V16ZM11 0V2H14.59L6 12.5L16 3.41V7H18V0H11Z"
                        fill="#3B3B3C"
                      />
                    </svg>
                  </Link>
                </div>
                <div style={{ fontWeight: "800" }}>
                  Junior Full-Stack Developer
                </div>
                <div>
                  Software Engineering | Data Technology | Interactive Art |
                  Digital Media Technology
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogLayout
